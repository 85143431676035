import colors from '_containers/Theme/corporate/colors';

export default {
  lightBanner: {
    headerColor: colors.all.brandPrimary3,
    color: colors.all.neutral700,
  },
  darkBanner: {
    headerColor: colors.all.neutralWhite,
    color: colors.all.darkText,
  },
  background: {
    yellow: colors.all.brandPrimary2,
    teal: colors.all.brandSecondary3,
    orange: colors.all.brandSecondary2,
    blue: colors.all.brandPrimary1,
    purple: colors.all.brandSecondary1,
  },
  container: {
    mediaPrint: {
      background: colors.background.tint1,
      color: colors.element.neutralBlack
    },
    minHeight: 308,
  },
  linkButton: {
    pathFillColor: colors.all.neutralWhite
  },
  outline: {
    focusColor: colors.all.neutralWhite
  }
};
