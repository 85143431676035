import { Text } from "@sitecore-jss/sitecore-jss-react";
import React, { FC } from "react";
import { Grid } from "_utils/components";
import { BookHinge } from "_utils/components/BookHinge";
import { stripHTML } from "_utils/helpers/html";
import { createSrcSet } from "_utils/media";
import { imagePlaceholderSrc } from "_utils/styles";
import { ImageHeight16by9, ImageSize } from "_utils/types/enums";
import { ImageBannerProps } from "./definitions";
import {
  BannerContent,
  BannerImage,
  Blurb,
  CategoryLabelLink,
  Container,
  Figure,
  Header,
  Image,
  LinkButton,
  SecondaryLinkButton,
  LinksDiv,
  SecondaryLinkButtonDark,
} from "./StyledPageBanner";

export const ImageBanner: FC<ImageBannerProps> = ({
  pageTitle,
  pageDescription,
  pageBannerTheme,
  pageImage,
  linkField,
  editMode,
  uid,
  trackingName,
  datasourceName,
  pageBannerCallToActionIcon,
  pageBannerCallToActionSecondaryCSSClass,
  pageBannerCallToActionSecondaryIcon,
  pageBannerCallToActionSecondaryLink,
  isCorporateSite = true,
}) => {
  const pageBannerThemeCheckDark =
    pageBannerTheme === "Black" || pageBannerTheme === "Blue";
  return (
    <Container
      className="page-banner-with-image"
      data-component
      id={uid}
      pageBannerTheme={pageBannerTheme}
    >
      <Grid.Container>
        <Grid.Row alignItems="stretch" config={{ gutters: [] }}>
          <BannerContent
            config={{ col: { xs: 12, md: 6 }, gutters: [] }}
            alignSelf="center"
          >
            {!isCorporateSite && (
              <CategoryLabelLink
                field={linkField}
                fallbackTitle={linkField?.fallbackTitle}
                linkSize="s"
                linkTheme="none"
                analyticsOptions={{
                  analyticsTrackingName: trackingName?.value,
                  datasourceName: datasourceName,
                }}
              />
            )}
            <Header
              pageBannerTheme={pageBannerTheme}
              isCorporateSite={isCorporateSite}
            >
              <Text field={pageTitle} />
            </Header>
            {(editMode || pageDescription?.value) && (
              <Blurb pageBannerTheme={pageBannerTheme}>
                <Text
                  field={
                    editMode
                      ? pageDescription
                      : { value: stripHTML(pageDescription?.value) }
                  }
                />
              </Blurb>
            )}
            {isCorporateSite && (
              <LinksDiv>
                <LinkButton
                  field={linkField}
                  fallbackTitle={linkField?.fallbackTitle}
                  linkSize="s"
                  linkTheme="none"
                  analyticsOptions={{
                    analyticsTrackingName: trackingName?.value,
                    datasourceName: datasourceName,
                  }}
                  iconName={pageBannerCallToActionIcon}
                />
                {pageBannerThemeCheckDark ? (
                  <SecondaryLinkButtonDark
                    field={pageBannerCallToActionSecondaryLink}
                    fallbackTitle={
                      pageBannerCallToActionSecondaryLink?.fallbackTitle
                    }
                    linkSize="s"
                    analyticsOptions={{
                      analyticsTrackingName: trackingName?.value,
                      datasourceName: datasourceName,
                    }}
                    iconName={pageBannerCallToActionSecondaryIcon}
                  />
                ) : (
                  <SecondaryLinkButton
                    field={pageBannerCallToActionSecondaryLink}
                    fallbackTitle={
                      pageBannerCallToActionSecondaryLink?.fallbackTitle
                    }
                    linkSize="s"
                    analyticsOptions={{
                      analyticsTrackingName: trackingName?.value,
                      datasourceName: datasourceName,
                    }}
                    iconName={pageBannerCallToActionSecondaryIcon}
                  />
                )}
              </LinksDiv>
            )}
          </BannerContent>
        </Grid.Row>
      </Grid.Container>
      <BannerImage pageBannerTheme={pageBannerTheme}>
        <Figure>
          <Image
            className="lazyload"
            draggable={false}
            field={
              editMode
                ? pageImage
                : {
                    value: {
                      src: imagePlaceholderSrc,
                      alt: "", // alt text is empty string, as this is a decorative image
                      "data-srcset": createSrcSet(pageImage?.value?.src, [
                        ImageSize.MW_384,
                        ImageSize.MW_640,
                        ImageSize.MW_768,
                        ImageSize.MW_1024,
                      ]),
                    },
                  }
            }
            height={ImageHeight16by9.MW_384}
            width={ImageSize.MW_384}
          />
        </Figure>
        {isCorporateSite && (
          <BookHinge desktopPlacement="left" mobilePlacement="top" />
        )}
      </BannerImage>
    </Container>
  );
};
