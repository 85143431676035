import { Link as SCLink } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React from 'react';

import Icon from '_components/Icon';
import NavItem from '_components/NavItem';
import {
  withEditMode,
  withEditModeNotice,
} from '_containers/BaseComponent';
import { getLink } from '../../helpers/link';

import {
  LinkProps,
  LinkSize,
  LinkTheme,
  LinkTypes,
} from './definitions';

import {
  EditorDiv,
  LinkChildrenWrapper,
  LinkIconWrapper,
  LinkTextWrapper,
  StyledLink,
  SvgWrapper,
} from './StyledLink';


const EditorText = compose(withEditModeNotice)(EditorDiv);

const Link: React.FC<LinkProps> = ({
  analyticsOptions,
  children,
  editMode,
  linkTheme,
  linkSize,
  className,
  field,
  fallbackTitle,
  childrenAfterText,
  childrenOnly,
  disabled,
  tag,
  hasNoHref,
  hideExternalIcon,
  iconName,
  noIcon = false,
  ...props
}) => {
  // Link with no href in editMode renders nothing with StyledLink in EE,
  // so instead if specified as a Link that hasNoHref, we just render the real thing (below).
  if (editMode && !hasNoHref) {
    const { href, text } = field?.value || {};

    return (
      <div className={className}>
        <EditorText
          noticeEnabled={href && !text && !fallbackTitle}
          noticeMessage={`No description or linked title for this link. The following text will be displayed for users: '${href}'`}
        >
          <StyledLink as="span" linkTheme={linkTheme} linkSize={linkSize}>
            <SCLink field={field} />
          </StyledLink>
        </EditorText>
      </div>
    );
  }

  const linkObj = getLink(field?.value, analyticsOptions);

  if (linkObj || hasNoHref) {
    return (
      <div className={className}>
        <NavItem
          className={field?.value?.class}
          StyledLink={StyledLink}
          link={linkObj}
          additionalStyledLinkProps={{ linkTheme, linkSize }}
          disabled={disabled}
          tag={tag}
          {...props}
        >
          {
            childrenOnly
              ? (
                <LinkChildrenWrapper>
                  {children}
                </LinkChildrenWrapper>
              ) : (
                <>
                  {
                    !childrenAfterText && children && (
                      <LinkChildrenWrapper>
                        {children}
                      </LinkChildrenWrapper>
                    )
                  }

                  <LinkTextWrapper>
                    {linkObj?.text || fallbackTitle || linkObj?.url}
                  </LinkTextWrapper>

                  {
                    childrenAfterText && children && (
                      <LinkChildrenWrapper>
                        {children}
                      </LinkChildrenWrapper>
                    )
                  }

                  {
                    !noIcon && !iconName && (!hideExternalIcon && linkObj?.type === LinkTypes.External
                      || !!hasNoHref && field?.value?.linktype === LinkTypes.External) && (
                      <LinkIconWrapper>
                        &#65279;
                        <SvgWrapper>
                          <Icon ariaHidden={true} name="open_in_new" />
                        </SvgWrapper>
                      </LinkIconWrapper>
                    )
                  }

                  {/* Featured link */}
                  {
                    !noIcon && !iconName && linkObj?.type === LinkTypes.Internal && linkTheme === 'featured' && (
                      <LinkIconWrapper className="featuredIcon">
                        &#65279;
                        <SvgWrapper>
                          <Icon ariaHidden={true} name="arrow_forward" />
                        </SvgWrapper>
                      </LinkIconWrapper>
                    )
                  }
                  {
                     !noIcon && iconName !== undefined && 
                        <LinkIconWrapper>
                          &#65279;
                          <SvgWrapper>
                            <Icon ariaHidden={true} name={iconName} />
                          </SvgWrapper>
                        </LinkIconWrapper>
                  }
                </>
              )
          }
        </NavItem>
      </div>
    );
  }

  return null;
};

Link.defaultProps = {
  tag: 'a',
  linkTheme: LinkTheme.Primary,
  linkSize: LinkSize.Default,
  linkAriaCurrent: null
};

export default compose(withEditMode)(Link);
